<template>
    <div class="px-5 py-5">
                <v-row>
                  <v-col md="1"></v-col>

                    <v-col md="4">
                      <h3 class="ml-5">{{  $t('title') }} : 
                        <span
                          style="font-weight: 100"
                        >
                          {{ title }}</span
                        >
                      </h3>
                    </v-col>
                    <v-col md="2"></v-col>
                    <v-col md="4">
                        <h3 class="ml-5"> {{ $t('date')}} :
                          <span
                            style="font-weight: 100"
                          >
                            {{ dateMessage }}</span
                          >
                        </h3> 
                    </v-col>
                    <v-col md="1"></v-col>
                </v-row>
                <v-row>
                  <v-col md="1"></v-col>
                    <v-col md="4">
                        <h3 class="ml-5">{{  $t('Sender')}} : 
                          <span
                            style="font-weight: 100"
                          >
                            {{ sender }}</span
                          >
                        </h3>
                    </v-col>
                    <v-col md="2"></v-col>
                    <v-col md="4">
                        <h3 class="ml-5">{{$t('Receiver')}} :
                          <span
                            style="font-weight: 100"
                          >
                            {{ receiver }}</span
                          >
                        </h3>
                    </v-col>
                    <v-col md="1"></v-col>
                </v-row>
               
                    <v-col>
                      <h2 class="ml-5">{{ $t('Message') }} </h2>
                      <v-spacer></v-spacer>
                       <v-row>
                        <v-col md="1"></v-col>
                        <v-col> {{ message }}</v-col>
                       </v-row>
                    </v-col>
                



          <v-row class="mt-5" v-if="items.attachments.length!=0">
            <v-col md="2">
            <h2 class="tests">{{ $t("attachments") }}</h2>
          </v-col>
               <v-col
            md="2"
            v-for="attachment in items.attachments"
            :key="attachment.id"
          >
            <div class="cardd">
              <div>
                <v-row>
                  <v-col md="9">
                    <img class="preview-img" :src="attachment.path" />
                  </v-col>
                  <v-col md="3">
                    <v-icon
                      color="red"
                      @click="
                        () => {
                          deleteAttachmentDialog = true;
                          attachmentId = attachment.id;
                          attachmentName = attachment.name;
                        }
                      "
                      >mdi-delete</v-icon
                    >
                    <div class="mt-5">
                      <FileComponent :Id="attachment.id"></FileComponent>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col md="9">
                    <div class="test" :title="attachment.name">
                      <span>
                        {{ attachment.name }} -

                        {{ Math.round(attachment.size / 1000) + "kb" }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-dialog v-model="deleteAttachmentDialog" width="500">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h2>? {{ $t("are you sure delete ") }}{{ attachmentName }}</h2>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn
                color="green"
                class="white--text"
                depressed
                @click="deleteAttachment(attachmentId)"
                :loading="deleteBtnLoading"
                >{{ $t("submit") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-row>
        <v-col v-if="type=='task'">
         
                  <h2 >
                  {{ $t('Tasks') }}
                  </h2>
                  <v-spacer></v-spacer>
            <v-col md="12" class="py-2" >
                <table  >
                  <tr>
                    <th> {{ $t('Task Name') }} </th>
                    <th> {{ $t('Deadline') }}</th>
                  
                  </tr>
                  <tr v-for="(el,ix) in items.tasks" :key="ix">
                    <td>
                      <v-text-field class="mb-6"
                        full-width
                        outlined
                        dense
                        hide-details
                        
                        disabled
                        flat
                        color="#757575"
                        v-model="el.name"
                        
                      ></v-text-field>
                    </td>
                      <td>
                    
                        <v-row >
                        <v-col >
                          <v-text-field 
                            :label="$t('date')" :dark="$store.state.isDarkMode"
                            disabled
                            outlined
                            dense
                            
                            flat
                            type="date"
                            v-model="el.date"
                          ></v-text-field>
                        </v-col>
                        <v-col >
                          <v-text-field 
                            v-model="el.time"
                            disabled
                            :label="$t('Time')" :dark="$store.state.isDarkMode"
                            outlined
                            dense
                            
                            flat
                            type="time"
                          ></v-text-field>
                        </v-col>
                        </v-row>
                    </td>
                  </tr>
                </table>
              </v-col>
        </v-col>
                    <!-- //////////////////////////////////////////////////Replay////////////////////////////////// -->
                    <v-spacer></v-spacer>
                   
        <template v-if="reply.length!=0">
          
          <v-col v-for="(el,ix) in items.reply" :key="ix">
            <v-divider class="mt-5" />

            <v-row>
                  

                    <v-col md="6">
                      <h2 class="ml-5">
                        
                          {{ el.sender }}
                        
                      </h2>
                    </v-col>
                    <v-col md="2"></v-col>
                    <v-col md="3">
                        <h3 class="ml-5"> 
                          
                            {{ el.date }}
                          
                        </h3> 
                    </v-col>
                </v-row>
         
          <template >
            <v-row>
                  <v-col md="1"></v-col>

                    <v-col md="4">
                      <h3 class="ml-5">
                       
                          {{ el.title }}
                        
                      </h3>
                    </v-col>
                    <v-col md="2"></v-col>
                   
                    <v-col md="1"></v-col>
                </v-row>
          
               
                    <v-col>
                     
                      <v-spacer></v-spacer>
                       <v-row>
                        <v-col md="1"></v-col>
                        <v-col> {{ el.message }}</v-col>
                       </v-row>
                    </v-col>
                



          <v-row class="mt-5" v-if="el.attachments.length!=0">
            <v-col md="2">
            <h2 class="tests">{{ $t("attachments") }}</h2>
          </v-col>
               <v-col
            md="2"
            v-for="attachment in el.attachments"
            :key="attachment.id"
          >
            <div class="cardd">
              <div>
                <v-row>
                  <v-col md="9">
                    <img class="preview-img" :src="attachment.path" />
                  </v-col>
                  <v-col md="3">
                    <v-icon
                      color="red"
                      @click="
                        () => {
                          deleteAttachmentDialog = true;
                          attachmentId = attachment.id;
                          attachmentName = attachment.name;
                        }
                      "
                      >mdi-delete</v-icon
                    >
                    <div class="mt-5">
                      <FileComponent :Id="attachment.id"></FileComponent>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col md="9">
                    <div class="test" :title="attachment.name">
                      <span>
                        {{ attachment.name }} -

                        {{ Math.round(attachment.size / 1000) + "kb" }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-dialog v-model="deleteAttachmentDialog" width="500">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h2>? {{ $t("are you sure delete ") }}{{ attachmentName }}</h2>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn
                color="green"
                class="white--text"
                depressed
                @click="deleteAttachment(attachmentId)"
                :loading="deleteBtnLoading"
                >{{ $t("submit") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-row>
        <v-col v-if="el.type=='task'">
         
                  <h2 >
                  {{ $t('Tasks') }}
                  </h2>
                  <v-spacer></v-spacer>
            <v-col md="12" class="py-2" >
                <table  >
                  <tr>
                    <th> {{ $t('Task Name') }} </th>
                    <th> {{ $t('Deadline') }}</th>
                  
                  </tr>
                  <tr v-for="(el,ix) in el.tasks" :key="ix">
                    <td>
                      <v-text-field class="mb-6"
                        full-width
                        outlined
                        dense
                        hide-details
                        
                        disabled
                        flat
                        color="#757575"
                        v-model="el.name"
                        
                      ></v-text-field>
                    </td>
                      <td>
                    
                        <v-row >
                        <v-col >
                          <v-text-field 
                            :label="$t('date')" :dark="$store.state.isDarkMode"
                            disabled
                            outlined
                            dense
                            
                            flat
                            type="date"
                            v-model="el.date"
                          ></v-text-field>
                        </v-col>
                        <v-col >
                          <v-text-field 
                            v-model="el.time"
                            disabled
                            :label="$t('Time')" :dark="$store.state.isDarkMode"
                            outlined
                            dense
                            
                            flat
                            type="time"
                          ></v-text-field>
                        </v-col>
                        </v-row>
                    </td>
                  </tr>
                </table>
              </v-col>
        </v-col>
          </template>
        </v-col>
        </template>

<!-- //////////////////////////////////////End Replay/////////////////////////// -->

                    <v-spacer></v-spacer>
                    <v-col></v-col>
               
                    <Footer v-if="returnAbility('message:relpy')" @reFetch="getMessages" :parent_id="parent_id" />

      

      



    </div>
  </template>
  
  <script>
  import axios from "axios";
  import i18n from '@/i18n';
  import debounce from "../../../../helpers/debounce";

  import FileComponent from "../teachers/download.vue";
  import Footer from './Footer.vue';

  
  export default {
    components: {
    
     FileComponent,
     Footer,

    },
    data() {
      return {
        actionMessage: null,
        
      deleteAttachmentDialog: false,
      deleteBtnLoading: false,
      attachmentName: null,
      attachmentId: null,
        idMessage: null,
        confirm_delete: false,
        tasks: [],
        attachedFiles: [],
        usersLoading:null,
        users: [],
        user_id: null,
        items: [],
        reply: [],
        title: null,
        message: null,
        dateMessage: null,
        type: 'mail',
        sender: null,
        receiver: null,
        receivers: [],
        receiversList: [],
        loading: false,
        dialog: false,
        timeMenu: false,
        timeMenuRef: null,
        dateMenu: false,
        deleteItemId: null,
        delete_dialog: false,
        parent_id: this.$route.params.id,
      };
    },
    watch:{
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/message/${this.deleteItemId}`).then((res) => {
          if (res.status == 200) {
            this.$Notifications(
              "تم الحذف  بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
        });
        this.getMessagesSent();
        this.delete_dialog = false;
        this.confirm_delete = false;
      }
    },
  },
    methods: {
        async deleteAttachment(id) {
            
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`/attachment/${id}`);
        this.deleteAttachmentDialog = false;
        this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
      async deleteMessage(item) {
      this.deleteItemId = item;
      this.delete_dialog = true;
    },
      
      editMessage(item){
        this.type = item.type;
        this.idMessage = item.id;
        this.title = item.title;
        this.message = item.message;
        for(let i=0;i<item.tasks.length;i++){
            this.tasks.push({
            name: item.tasks[i].name,
            date: item.tasks[i].date,
            time: item.tasks[i].time,
            });
        }
        

        this.dialog = true;
        this.actionMessage = 'edit';
      },
      createMessage(){
        this.dialog = true;
        this.actionMessage = 'send';
        this.addNewTask();
      },
      deleteRowT(index) {
      if (this.tasks.length == 1) return;
      this.tasks.splice(index, 1);
    },
    addNewTask(focus = true) {
      this.tasks.push({
        name: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      time: `${
        String(new Date().getHours()).length == 1
          ? `0${new Date().getHours()}`
          : new Date().getHours()
      }:${
        String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
      }`,
      });},
      delChild(i){
      this.receiversList.splice(i,1);
    },
      inputChild(){
      this.receiversList.push(this.user_id);
      
      this.user_id = null;
      this.users = null;
    },
      async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
    handleFileChange(event) {
      const files = event.target.files;
      this.attachedFiles = Array.from(files);
    },
       async sendMessage(){
        
        let formData = new FormData();
        for (let i = 0; i < this.attachedFiles.length; i++) {
          formData.append('files[]', this.attachedFiles[i]);
        }
        for (let i = 0; i < this.receiversList.length; i++) {
          formData.append('receivers[]', this.receiversList[i].id);
        }
        for (let i = 0; i < this.tasks.length; i++) {
          formData.append(`tasks[${i}][name]`, this.tasks[i].name);
          formData.append(`tasks[${i}][dead_line]`, `${this.tasks[i].date} ${this.tasks[i].time}` );
        }
            formData.append('type', this.type);
            formData.append('title', this.title);
            formData.append('message', this.message);
        // this.receiversList.forEach((el)=>{
        //   this.receivers.push(el.id);
        // })

        if(this.actionMessage == 'send'){
          const res = await axios.post("/message",formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
           
          
          if (res.status == 200) {
            this.$Notifications(
              "تم إرسال الرسالة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
           
          }
        }else {
          const res = await axios.post(`/message/mail/${this.idMessage}`,formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }); 
            if (res.status == 200) {
            this.$Notifications(
              "تم تعديل الرسالة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
        }
        await this.getMessages();
            this.cancel();
        },
        cancel(){
            this.dialog = false;
            this.title = null;
            this.message = null;
            this.type = 'mail';
            this.tasks = [];
            this.receivers = [];
            this.receiversList = [];
            this.attachedFiles = [];
            this.actionMessage = null;
        },
      async getMessages() {
        
        this.loading = true;
        const res = await axios.get(`/message/mail/${this.$route.params.id}`);
        this.items = res.data.data;
        
          console.log(this.items);
        this.title = this.items.title;
        this.type = this.items.type;
        this.dateMessage = this.items.date;
        this.message = this.items.message;
        this.receiver = this.items.receiver;
        this.sender = this.items.sender;
        this.reply = this.items.reply;
        

        this.loading = false;
      },
    },
    created() {

      this.getMessages();
      
    },
  };
  </script>
  
  <style scoped lang="scss">

td,
th {
  padding: 2px 10px;

}



  textarea {
    width: 100%;
    height: 200px; /* Set the desired height for the textarea */
    padding: 10px;
    box-sizing: border-box;
  }
  .btn {
  text-decoration: none;
  height: 41px;
  color: white;
  min-width: 73px;
  padding: 0 18.2222222222px;
  font-size: 1rem;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: box-shadow, transform, opacity;
  transition-property: box-shadow, transform, opacity, -webkit-box-shadow,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
  </style>
  