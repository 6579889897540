<template>
    <footer class="footer">
        <v-col class="d-flex justify-end">
            <v-btn
                color="green"
                class="white--text"
                @click="createMessageReply"
              >
                <v-icon size="20">mdi-plus</v-icon>
                {{ $t("Reply") }}
              </v-btn>
        </v-col> 
        <template>
            <v-dialog v-model="dialog" persistent max-width="700">
                <v-card>
                    <v-card-title class="text-h5 d-flex justify-center" style="color: red;">
                        <h2>{{ $t('Create Reply') }}</h2>
                    </v-card-title>
                    <v-col>
                        <v-text-field v-model="title" :label="$t('Message Title')">
                            
                        </v-text-field>
                    </v-col>  
                    <v-col>
                      <v-select
                        v-model="type"  
                        color="#757575" 
                        :label="$t('type')"
                        :items="typeList"
                        item-text="name"
                        item-value="value"
                        
                      ></v-select>
                    </v-col>
                    
                   
                    <v-col>
                      <div >
                        {{ $t('Message') }}
                        <textarea style="border: 0.5px solid black;"  v-model="message"  rows="5">
                            
                        </textarea>
                      </div>
                      <!-- <quill-editor
                        v-model=  "message"
                        ref="myQuillEditor"
                          >
                      </quill-editor> -->
                    </v-col>
                    <v-col>
                      <v-col md="2" style="display: flex; justify: start">
                        <div>
                          <input type="file" id="fileInput"
                          class="hidden-input"  multiple 
                          @change="handleFileChange" />
                          <label for="fileInput" class="file-label">
                            <u class="btn">
                              <v-icon size="20" color="white">mdi-plus</v-icon
                              >{{ $t("add attachment") }}</u
                            >
                          </label>
                          <div>
                            <ul>
                              <li v-for="file in attachedFiles" :key="file.name">
                                {{ file.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </v-col>
                    </v-col>

                    <v-col v-if="type=='task'">
                      
                      
                              <h2 class="d-flex justify-center">
                              {{ $t('Tasks') }}
                              </h2>
                              <v-spacer></v-spacer>
                        <v-col md="12" class="py-2" >
                            <table  >
                              <tr>
                                <th> {{ $t('Task Name') }} </th>
                                <th> {{ $t('Deadline') }}</th>
                                <th ></th>
                              </tr>
                              <tr v-for="(el,ix) in tasks" :key="ix">
                                <td>
                                  <v-text-field class="mb-6"
                                    full-width
                                    outlined
                                    dense
                                    hide-details
                                    
                                    flat
                                    color="#757575"
                                    v-model="el.name"
                                    @keydown.enter="addNewTask"
                                  ></v-text-field>
                                </td>
                                <!-- <v-col md="3" class="py-0"> -->
                                  <td>
                                
                                   <v-row >
                                    <v-col >
                                      <v-text-field 
                                        :label="$t('date')" :dark="$store.state.isDarkMode"
                                        outlined
                                        dense
                                        
                                    flat
                                        type="date"
                                        v-model="el.date"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col >
                                      <v-text-field 
                                        v-model="el.time"
                                        :label="$t('Time')" :dark="$store.state.isDarkMode"
                                        outlined
                                        dense
                                        
                                    flat
                                       type="time"
                                      ></v-text-field>
                                    </v-col>
                                   </v-row>
                                      
                                 
                                  
                                </td>
                   
                               
                                <td>
                                  <v-icon class="mb-6"
                                    @click="deleteRowT(ix)"
                                    color="red"
                                    style="border: 1px solid red; border-radius: 50%"
                                    >mdi-minus</v-icon
                                  >
                                  
                                </td>
                              </tr>
                            </table>
                          </v-col>
                            
                          
                    

                    </v-col>
                    
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text @click="cancel">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="blue" text @click="sendMessage">
                        <span > {{ $t('Send')  }}</span>
                        
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-col></v-col>
                </v-card>
            </v-dialog>
        </template>
    </footer>
  </template>
  
  <script>
  import axios from "axios";
  import i18n from '@/i18n';


  export default {
    name: 'Footer',
    props:{
      parent_id: Number,
    },
    data() {
      return {
        dialog: false,
        typeList: [
          {
            name: i18n.t("Mail"),
            value: "mail",
          },
          {
            name: i18n.t("Task"),
            value: "task",
          },
        ],
        tasks: [],
        attachedFiles: [],
        title: null,
        message: null,
        type: 'mail',
      
      }
    },
    methods: {
      createMessageReply(){
        this.dialog = true;
        this.addNewTask();
      },
      cancel(){
        this.dialog = false;
        this.title = null;
        this.message = null;
        this.type = 'mail';
        this.tasks = [];
        this.attachedFiles = [];
      },
      addNewTask(focus = true) {
      this.tasks.push({
        name: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      time: `${
        String(new Date().getHours()).length == 1
          ? `0${new Date().getHours()}`
          : new Date().getHours()
      }:${
        String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
      }`,
      });},
      handleFileChange(event) {
        const files = event.target.files;
        this.attachedFiles = Array.from(files);
      }, 
      async sendMessage(){
        
        let formData = new FormData();
        for (let i = 0; i < this.attachedFiles.length; i++) {
          formData.append('files[]', this.attachedFiles[i]);
        }
        
        for (let i = 0; i < this.tasks.length; i++) {
          formData.append(`tasks[${i}][name]`, this.tasks[i].name);
          formData.append(`tasks[${i}][dead_line]`, `${this.tasks[i].date} ${this.tasks[i].time}` );
        }
            formData.append('type', this.type);
            formData.append('title', this.title);
            formData.append('message', this.message);
            formData.append('parent_id', this.parent_id);
       
        
          const res = await axios.post("/message/reply",formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
           
          
          if (res.status == 200) {
            this.$Notifications(
              "تم إرسال الرد بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
           
          }
          this.$emit("reFetch");
    
            this.cancel();
        },
    },

  }
  </script>
  
  <style>
   textarea {
    width: 100%;
    height: 200px; /* Set the desired height for the textarea */
    padding: 10px;
    box-sizing: border-box;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 75%;
    background-color: white;
    /* Add any additional styling as needed */
  }
  </style>
  